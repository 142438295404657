<script setup lang="ts">
// vue
import { onMounted } from 'vue'

// types
import type { Nullable } from '@revolutionprep/types'

// utilities
import { createPicker, type EmojiSelection } from 'picmo'

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits(['select-emoji'])

/**
 * lifecycle hook
 * ==================================================================
 */
onMounted(() => {
  // The picker must have a root element to insert itself into
  const rootElement: Nullable<HTMLElement> =
    document.querySelector('.emoji-picker-container')

  if (rootElement) {
    // Create the picker
    const picker = createPicker({
      emojisPerRow: 5,
      categories: [
        'recents',
        'smileys-emotion',
        'animals-nature',
        'people-body',
        'activities'
      ],
      rootElement,
      visibleRows: 4
    })

    // The picker emits an event when an emoji is selected
    picker.addEventListener('emoji:select', (event: EmojiSelection) => {
      emit('select-emoji', event.emoji)
    })
  }
})
</script>

<template>
  <div class="emoji-picker-container" />
</template>

<script setup lang="ts">
// vue
import { ref } from 'vue'

// components
import EmojiPicker from '../emoji-picker/EmojiPicker.vue'

/**
 * emitted events
 * ==================================================================
 */
const emit = defineEmits(['select-emoji'])

/**
 * state
 * ==================================================================
 */
const showMenu = ref(false)

/**
 * methods
 * ==================================================================
 */
function selectEmoji (emoji: string) {
  emit('select-emoji', emoji)
  showMenu.value = false
}
</script>

<template>
  <div class="text-center">
    <v-menu
      v-model="showMenu"
      close-on-content-click
      location="top start"
    >
      <template #activator="{ props }">
        <v-btn
          elevation="0"
          icon
          v-bind="props"
        >
          <v-icon color="secondary">
            mdi-emoticon-happy-outline
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <client-only>
          <EmojiPicker @select-emoji="selectEmoji($event)" />
        </client-only>
      </v-card>
    </v-menu>
  </div>
</template>
